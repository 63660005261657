<template>
  <div style="margin: 0 auto">
    <!-- <span>piechart</span> -->
    <!-- 图表高度，若想给图表指定高度的话就必须给承载 ECharts 的容器指定高度，否则它会使用默认高度，给它的父容器定高也是不行的。  -->
    <div :id="id" :style="chartSytle"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "pieChart",
  props: {
    id: {
      type: String,
      default: "myPieChart",
    },
    option: {
      type: Object,
      default: () => {
        // {
        //   title: {
        //     text: "测试";
        //   }
        //   tooltip: {
        //   }
        //   xAxis: {
        //     data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"];
        //   }
        //   yAxis: {
        //   }
        //   series: [
        //     {
        //       name: "销量",
        //       type: "bar",
        //       data: [5, 20, 36, 10, 10, 20],
        //     },
        //   ];
        // }
      },
    },
    chartSytle: {
      type: String,
      default: "width: 100%; height: 300px",
    },
    showLoadingStatus: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      kChart: null,
      chartData: {
        title: {
          text: "",
        },
        tooltip: {},
        xAxis: {
          data: [],
        },
        yAxis: {},
        series: [,],
      },
    };
  },
  computed: {},
  components: {},
  methods: {
    refreshData() {
      this.kChart.setOption(this.option);
    },
  },
  async mounted() {
    // 初始化时机，初始化不能在 created 生命周期里面，因为这时候还没有生成 DOM，所以为它指定的容器还不存在，也就无法初始化
    // 1. 基于准备好的dom，初始化echarts实例,使用 svg模式（svg模式适应移动端）
    // console.log(this.option);

    const ChartEle = document.getElementById(this.id);
    this.kChart = echarts.init(ChartEle, "macarons"); //, { renderer: 'svg' }
    // 2. 设置数据
    this.kChart.setOption(this.option);
    // 3. 图标根据窗口大小自动缩放

    window.addEventListener("resize", this.kChart.resize);
    // 4. 初始数据
    // await this.getoption();
  },

  watch: {
    option() {
      this.refreshData();
    },
    showLoadingStatus() {
      if (this.showLoadingStatus) {
        this.kChart.showLoading({
          text: "加载中",
          color: "#4cbbff",
          textColor: "#4cbbff",
          maskColor: "rgba(0,0,0,0)",
        });
      } else {
        this.kChart.hideLoading();
      }
    },
  },

  async created() {},
  beforeDestroy() {
    // 在 vue 组件销毁之前，应该要将 ECharts 实例销毁，以避免内存泄漏的问题
    if (!this.kChart) {
      return;
    }
    this.kChart.dispose();
    this.kChart = null;
  },
};
</script>
