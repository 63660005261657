<template>
  <div style="text-align: left">
    <!-- 环比 -->
    <div
      style="margin-top: 10px"
      v-if="optionHuanBiRadio && optionHuanBiRadio.title"
    >
      <div style="padding: 10px 20px" v-if="showVal">
        环比增长:
        <span
          :class="
            detailInfo['环比增长'] && detailInfo['环比增长'].indexOf('+') > -1
              ? 'tipsHuanbiupColor'
              : 'tipsHuanbiDownColor'
          "
        >
          {{ detailInfo["环比增长"] }}
        </span>
      </div>
      <pie-chart
        :chartSytle="`width:90vw; height: 300px`"
        ref="huanBiRef"
        id="huanBiRefId"
        :option="optionHuanBiRadio"
      ></pie-chart>
    </div>

    <!-- 去年同期同比 -->
    <div
      style="margin-top: 10px"
      v-if="optionTongBiRadio && optionTongBiRadio.title"
    >
      <div style="padding: 10px 20px" v-if="showVal">
        同比增长:
        <span
          :class="
            detailInfo['同比增长'] && detailInfo['同比增长'].indexOf('+') > -1
              ? 'tipsHuanbiupColor'
              : 'tipsHuanbiDownColor'
          "
        >
          {{ detailInfo["同比增长"] }}
        </span>
      </div>

      <pie-chart
        :chartSytle="`width:90vw; height: 300px`"
        ref="tongBiRef"
        id="tongBiRefId"
        :option="optionTongBiRadio"
      ></pie-chart>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";
import pieChart from "../components/pieChart.vue";
export default {
  name: "huanBiTongBiCom",
  components: {
    pieChart,
  },

  data() {
    return {};
  },
  props: {
    detailInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    optionHuanBiRadio: {
      type: Object,
      default: () => {
        return {};
      },
    },
    optionTongBiRadio: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showVal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},

  destroyed() {},
  async created() {},
};
</script>

<style scoped>
.deptMainTop {
  text-align: center;
  /* color: white; */
  padding: 15px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.deptMainTop .box-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}
.deptMainTop .box-item span {
  flex: 1;
  color: #b57464;
  font-size: 16px;
}

.deptMainTop .box-item .numColr,
.numColor {
  font-size: 16px;
  font-weight: bold;
  color: #866060;
}
.deptMainTop .box-item .descColor,
.descColor {
  color: #bbb;
  overflow: hidden;
  font-size: 10px;
}
</style>
