<template>
  <div class="main">
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>

    <common-top
      :title="title"
      @search="searchUserStatus = false"
      :isShowSearch="isUserPm"
    ></common-top>

    <loading-cmp v-if="showLoad"></loading-cmp>

    <Modal
      v-model="showModalAll"
      fullscreen
      @onCancel="showModalAll = false"
      @onOk="showModalAll = false"
      :closable="false"
    >
      <p slot="header" style="text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span> 时段活跃度对比 </span>
      </p>

      <div>
        <pie-chart
          :chartSytle="`width:98vw; height: 600px`"
          ref="hourlyAll"
          id="hourlyidAll"
          :option="optionHourlyAll"
        ></pie-chart>
      </div>
      <div slot="footer" style="text-align: center">
        <Button type="info" size="large" @click="showModalAll = false"
          >确定</Button
        >
      </div>
    </Modal>

    <Modal
      v-model="showModal"
      fullscreen
      @onCancel="showModal = false"
      @onOk="showModal = false"
      :closable="false"
    >
      <p slot="header" style="text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>{{ gethourly_date }}</span>
      </p>

      <div>
        <pie-chart
          :chartSytle="`width:98vw; height: 300px`"
          ref="hourly"
          id="hourlyid"
          :option="optionHourly"
        ></pie-chart>
      </div>
      <div slot="footer" style="text-align: center">
        <Button type="info" size="large" @click="showModal = false"
          >确定</Button
        >
      </div>
    </Modal>

    <div class="scrollDiv" v-show="isUserPm" style="margin-top: 10px">
      <desc-com :startDate="startDate" :endDate="endDate"></desc-com>
      <div v-show="!searchUserStatus">
        <user-list
          @searchBack="searchUserStatus = true"
          @chooseItem="chooseItem"
        >
        </user-list>
      </div>
      <div v-show="searchUserStatus">
        <div>
          <div style="width: 100%">
            <pie-chart
              :chartSytle="`width:98vw; height: 300px`"
              ref="daily"
              id="dailyid"
              :option="optionDaily"
            ></pie-chart>
          </div>
        </div>
        <div
          style="
            box-shadow: 0 1px 6px rgb(0 0 0 / 6%);
            padding: 10px 20px;
            text-align: left;
            margin: 20px 0;
            font-weight: bold;
            display: flex;
          "
        >
          <div style="flex: 1">日活跃对比图</div>
          <div
            style="
              text-align: right;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <div @click="run" style="flex: 1; color: #515a6e">查看对比图</div>
            <svg
              style="display: block"
              t="1647959630127"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="8458"
              width="16"
              height="16"
            >
              <path
                d="M865.28 202.5472c-17.1008-15.2576-41.0624-19.6608-62.5664-11.5712L177.7664 427.1104c-23.2448 8.8064-38.5024 29.696-39.6288 54.5792-1.1264 24.8832 11.9808 47.104 34.4064 58.0608l97.5872 47.7184c1.9456 0.9216 3.6864 2.2528 5.2224 3.6864 10.1376 26.112 50.176 128.4096 67.9936 165.376 9.0112 18.8416 25.6 32.0512 40.96 37.7856-1.024-0.1024-2.1504-0.3072-3.3792-0.512 2.9696 1.1264 6.0416 2.048 9.216 2.6624 20.2752 4.096 41.0624-2.1504 55.6032-16.7936l36.352-36.352c6.4512-6.4512 16.5888-7.8848 24.576-3.3792l156.5696 88.8832c9.4208 5.3248 19.8656 8.0896 30.3104 8.0896 8.192 0 16.4864-1.6384 24.2688-5.0176 17.8176-7.68 30.72-22.8352 35.4304-41.6768l130.7648-527.1552c5.632-22.1184-1.6384-45.3632-18.7392-60.5184zM314.2656 578.56l335.0528-191.6928L460.1856 580.608c-3.072 3.1744-5.3248 6.7584-6.8608 10.9568-0.1024 0.2048-0.1024 0.3072-0.2048 0.512-0.4096 1.2288-37.7856 111.5136-59.904 161.3824-4.5056-2.9696-9.9328-7.7824-13.1072-14.4384-16.384-34.4064-54.5792-131.7888-65.8432-160.4608z"
                p-id="8459"
              ></path>
            </svg>
          </div>
        </div>
        <Table stripe :columns="columns1" :data="dataDaily">
          <template slot-scope="{ row }" slot="count">
            <span>{{ row.count }}</span>
            <Button @click="intoDetail(row.date_str)">时段活跃</Button>
          </template>
        </Table>

        <div style="box-shadow: 0 1px 6px #afb1b6">
          <huan-bi-tong-bi-com
            :optionHuanBiRadio="optionHuanBiRadio"
            :optionTongBiRadio="optionTongBiRadio"
            :detailInfo="huanbiTongBiDetail"
            :showVal="true"
            ref="huanbiRef"
          ></huan-bi-tong-bi-com>
        </div>
      </div>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";
import moment from "moment";
import {
  wxConPost,
  getUserPm,
  getRadio,
  getRadioDate,
  getYearOnYearBasisDate,
  getOptBar,
} from "../../api/httpApi";
import wxLoginApi from "../../api/wxLoginApi";
import commonTop from "../../components/commonTop.vue";
import noPassCom from "../../components/noPassCom.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import deptTree from "../../components/deptTree.vue";
import pieChart from "../../components/pieChart.vue";
import userList from "../../components/userList.vue";
import descCom from "../../components/descCom.vue";
import huanBiTongBiCom from "../../components/huanBiTongBiCom.vue";

export default {
  name: "personJifen",
  components: {
    loadingCmp,
    noPassCom,
    deptTree,
    diaLogliu,
    userList,
    commonTop,
    pieChart,
    descCom,
    huanBiTongBiCom,
  },

  data() {
    return {
      optionHuanBiRadio: {},
      optionTongBiRadio: {},
      huanbiTongBiDetail: {},

      initAllActiveStatus: false,

      title: "用户活跃度",
      // 时段活跃
      showModal: false,
      gethourly_date: "",

      // 时段活跃度对比
      showModalAll: false,

      isUserPm: false,
      showLoad: true, // 显示加载框

      wxUser: {},
      searchUserStatus: true, // 显示用户列表 | 显示编辑用户
      toSearchData: false, // 用户组件是否查询

      detailInfo: {}, // 积分详情
      startDate: "2022-01-01",
      endDate: "2022-02-20",

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      dataDaily: [],
      data_24h: [],

      columns1: [
        {
          title: "日期",
          key: "date_str",
          align: "center",
        },

        {
          title: "活跃度",
          key: "action",
          // width: 150,
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", params.row.count)]);
          },
        },
        {
          title: "活跃度",
          key: "action",
          // width: 150,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {},
                  style: {
                    marginRight: "5px",
                    marginLeft: "5px",
                  },
                  on: {
                    click: () => {
                      this.intoDetail(params.row.date_str);
                    },
                  },
                },
                "详情"
              ),
            ]);
          },
        },
      ],

      xAxis: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      series: [],
    };
  },

  methods: {
    intoDetail(date_str) {
      this.showModal = true;
      this.gethourlyData(date_str);
      this.gethourly_date = date_str;
    },
    async gethourlyData(date_hourly) {
      try {
        this.showLoad = true;
        let _query = {
          date: date_hourly,
          isUser: true,
        };
        if (this.wxUser.userid) {
          _query["userid"] = this.wxUser.userid;
        }
        const zky = await wxConPost(
          "/services/gttt-stats-mgt/test/user_activate_hourly",
          _query
        );
        this.showLoad = false;
        this.data_24h = zky.data;
        this.$nextTick(() => {
          this.$refs.hourly.refreshData();
        });
      } catch (e) {}
    },

    chooseItem(wxUser) {
      this.wxUser = wxUser;
      this.searchUserStatus = true;
      // 重新获取信息
      this.getData();
    },

    back() {
      this.$router.push({ path: "/statsmain" });
    },
    async getData() {
      try {
        this.showLoad = true;

        let _query = {
          startDate: this.startDate,
          endDate: this.endDate,
          isUser: true,
        };

        _query["userid"] = this.wxUser.userid;
        const result = await wxConPost(
          "/services/gttt-wxstats-mgt/activity/deptDailyActivity",
          _query
        );
        this.dataDaily = result.data;

        this.$nextTick(() => {
          this.$refs.daily.refreshData();
        });

        this.showLoad = false;

        this.getRadioAndYearOnYearData(_query);
      } catch (e) {
        console.log("----", e);
        this.$Notice.warning({
          title: "请稍后重试",
        });
        this.showLoad = false;
      }
    },
    async initAllActive() {
      this.series = [];
      let _dataDaily = this.dataDaily;
      if (_dataDaily.length > 4) {
        _dataDaily = this.dataDaily.slice(
          _dataDaily.length - 4,
          _dataDaily.length
        );
      }

      for (const item of _dataDaily) {
        const date_str = item.date_str;
        let _query = {
          date: date_str,
          isUser: true,
        };
        if (this.wxUser.userid) {
          _query["userid"] = this.wxUser.userid;
        }
        const zky = await wxConPost(
          "/services/gttt-stats-mgt/test/user_activate_hourly",
          _query
        );
        if (zky) {
          const _data = [];
          //  _.map(zky.data, "count");
          for (const iterator of this.xAxis) {
            let hit = _.find(zky.data, (it) => {
              return it._id == iterator;
            });
            if (hit && hit.count) {
              _data.push(hit.count);
            } else {
              _data.push(0);
            }
          }
          this.series.push({
            name: date_str,
            smooth: true, //这句就是让曲线变平滑的
            symbol: "none", //这句就是去掉点的,
            type: "line",
            data: _data,
          });
        }
      }
    },
    async run() {
      this.showModalAll = true;
      this.showLoad = true;
      await this.initAllActive();
      this.showLoad = false;
      this.$nextTick(() => {
        this.$refs.hourlyAll.refreshData();
      });
    },

    /******************** 环比/同比 */
    async commonRatioDataFun(query, type) {
      try {
        let _query = query;
        //  计算周期
        let { startDate, endDate } = getRadioDate(this.startDate, this.endDate);
        if (type == "tongbi") {
          const dateObj = getYearOnYearBasisDate(this.startDate, this.endDate);
          startDate = dateObj.startDate;
          endDate = dateObj.endDate;
        }

        _query["startDate"] = startDate;
        _query["endDate"] = endDate;

        const ret = await wxConPost(
          "/services/gttt-wxstats-mgt/activity/deptDailyActivity",
          _query
        );
        return ret.data || [];
      } catch (e) {
        return [];
      }
    },

    dealCommonRadioData(data) {
      const detailInfo = {
        总活跃度: 0,
      };
      const chartData = [];
      for (const iterator of data) {
        detailInfo["总活跃度"] += iterator["count"];
        // chartData.push(iterator["integralAll"]);
      }
      return {
        detailInfo,
        chartData,
      };
    },

    async getRadioAndYearOnYearData(query) {
      const huanbiData = await this.commonRatioDataFun(query);
      // const tongbiData = await this.commonRatioDataFun(query, "tongbi");

      const huanbiDataObj = this.dealCommonRadioData(huanbiData);
      // const tongbiDataObj = this.dealCommonRadioData(tongbiData);

      const detailInfo = {
        总活跃度: 0,
      };

      for (const iterator of this.dataDaily) {
        detailInfo["总活跃度"] += iterator["count"];
      }

      // 环比/同比
      this.optionHuanBiRadio = getOptBar(
        "活跃度环比趋势",
        "活跃度",
        [huanbiDataObj["detailInfo"]["总活跃度"], detailInfo["总活跃度"]],
        ["上期数", "本期数"]
      );

      // this.optionTongBiRadio = getOptBar(
      //   "活跃度同比趋势",
      //   "活跃度",
      //   [tongbiDataObj["detailInfo"]["总活跃度"], detailInfo["总活跃度"]],
      //   ["同期数", "本期数"]
      // );

      this.huanbiTongBiDetail = {
        环比增长: getRadio(
          detailInfo["总活跃度"],
          huanbiDataObj["detailInfo"]["总活跃度"]
        ),
        // 同比增长: getRadio(
        //   this.detailInfo["平台总积分"],
        //   tongbiDataObj["detailInfo"]["总活跃度"]
        // ),
      };
    },
  },
  computed: {
    optionHourly() {
      let optionHour = {
        // title: {
        //   text: "时段活跃度",
        // },
        title: {
          left: "center",
          text: "时段活跃度",
        },
        tooltip: {
          trigger: "axis",
        },
        // legend: {
        //   data: ["时段活跃度"],
        // },
        toolbox: {
          show: false,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.data_24h.map((item) => {
              return item._id;
            }),
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            smooth: true, //这句就是让曲线变平滑的
            symbol: "none", //这句就是去掉点的
            name: "活跃度",
            type: "line",
            data: this.data_24h.map((item) => {
              return item.count;
            }),
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "平均值" }],
            },
          },
        ],
      };
      return optionHour;
    },
    optionDaily() {
      let optionDay = {
        tooltip: {
          trigger: "axis",
        },
        title: {
          left: "center",
          text: "每日活跃度",
        },
        grid: {
          left: "1%",
          right: "2%",
          bottom: "0",
          containLabel: true,
        },
        // legend: {
        //   data: ["每日活跃度"],
        // },

        toolbox: {
          show: false,
          feature: {
            mark: { show: true },
            // dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            // restore: { show: true },
            // saveAsImage: { show: true },
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.dataDaily.map((item) => {
              return item.date_str;
            }),
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            smooth: true, //这句就是让曲线变平滑的
            symbol: "none", //这句就是去掉点的
            name: "活跃度",
            type: "line",
            showSymbol: false,
            data: this.dataDaily.map((item) => {
              return item.count;
            }),
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "平均值" }],
            },
          },
        ],
      };
      return optionDay;
    },

    optionHourlyAll() {
      let optionHour = {
        // title: {
        //   text: "Stacked Line",
        // },

        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: this.xAxis,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xAxis,
        },
        yAxis: {
          type: "value",
        },
        series: this.series,
      };
      return optionHour;
    },
  },
  async created() {
    // 默认最近7天的数据
    this.startDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
    const userPm = getUserPm();
    if (userPm) {
      this.isUserPm = true;
      wxLoginApi.onReady(async () => {
        this.wxUser = wxLoginApi.getUser();
        console.log("---", this.wxUser);
        this.getData();
      });
    } else {
      this.showLoad = false;
    }
  },
  watch: {},
};
</script>

<style scoped>
.deptMainTop {
  text-align: center;
  /* color: white; */
  padding: 15px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.deptMainTop .box-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}
.deptMainTop .box-item span {
  flex: 1;
  color: #b57464;
  font-size: 16px;
}

.deptMainTop .box-item .numColr,
.numColor {
  font-size: 16px;
  font-weight: bold;
  color: #866060;
}
.deptMainTop .box-item .descColor,
.descColor {
  color: #bbb;
  overflow: hidden;
  font-size: 10px;
}
.desc p {
  margin-top: 4px;
  color: #666;
}
</style>
